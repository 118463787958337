<template>
  <div>
    <h1>Words</h1>
    <table id="words" class="ui celled compact table">
      <thead>
        <tr>
          <th>English</th>
          <th>German</th>
		  <th>French</th>
          <th colspan="3">Menu</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(word, i) in words" :key="i">
          <td>{{ word.english }}</td>
          <td>{{ word.german }}</td>
		  <td>{{ word.french }}</td>
          <td width="75" class="center aligned">
            <router-link class="ui button green" :to="{ name: 'show', params: { id: word._id } }">
              Show
            </router-link>
          </td>
          <td width="75" class="center aligned">
            <router-link class="ui button yellow" :to="{ name: 'edit', params: { id: word._id } }">
              Edit
            </router-link>
          </td>
          <td
            width="75"
            class="center aligned"
            @click.prevent="onDelete(word._id)"
          >
            <a class="ui button red" href="`/words/${word._id}`"> Delete</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { api } from "../helpers/helpers";

export default {
  name: "words",
  data() {
    return {
      words: [],
    };
  },
  methods: {
    async onDelete(id) {
      const sure = window.confirm("Are you sure ?");
      if (!sure) return;
      await api.deleteWord(id);
      this.flash("Word deleted successfully", "success");
      const newWords = this.words.filter((word) => word._id !== id);
      this.words = newWords;
    },
  },
  async mounted() {
    this.words = await api.getWords();
  },
};
</script>
